import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: []
}

const settingSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateSettingAction: (state, action) => {
            if(!action.payload || action.payload.length <= 0) {
                state.data = [];
                return;
            }

            state.data = action.payload.data;
        }
    }
});

export const { updateSettingAction } = settingSlice.actions;

export default settingSlice.reducer;