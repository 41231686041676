import * as React from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import {IPaginationColumn} from "../../interface/IPaginationProps";
import {giftCardCountryRecordAction} from "../../reducer/giftCardCountryReducer";
import {useNavigate} from "react-router-dom";

const columns:IPaginationColumn  = {
    //id: { label: 'ID', align: 'left', format: (v:any) => v },
    name: { label: 'Name', align: 'left', format: (v:any) => v },
    url: { label: 'Url', align: 'left', format: (v:any) => v },
    type: { label: 'Type', format: (v:any) => v },
    createdAt: { label: 'Date', format: (value: any) =>  moment(value).format("dddd, MMMM Do YYYY") },
};


export default function GiftCardCountry() {

    const rows = useSelector((state: any) => state.giftCardCountry.data );
    const count = useSelector((state: any) => state.giftCardCountry.count );
    const navigate = useNavigate();

    const createAction = () => {
        navigate('/dashboard/gift-card-country/create', {replace: true});
    };

    const editAction = (id: any, data: object) => {
        localStorage.setItem('giftCardCountry', JSON.stringify(data));
        navigate(`/dashboard/gift-card-country/edit/${id}`, {replace: true});
    };

    const viewAction = (id: any, data: object) => {
        localStorage.setItem('giftCardCountry', JSON.stringify(data));
        navigate(`/dashboard/gift-card-country/view/${id}`, {replace: true});
    };

    return (
        <PaginationRecord columns={columns} path='admin/admin-giftcard/country'
                          recordAction={giftCardCountryRecordAction}
                          data={rows} dataSize={count}
                          name='giftCardCountry'
                          createAction={createAction}
                          editAction={editAction}
                          viewAction={viewAction}
                          deleteUrlPath={`${process.env.REACT_APP_API_URL}/admin/admin-giftcard/country`}
        />
    );
}