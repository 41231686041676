import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    count: 0
}

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        profileRecordAction: (state, action) => {
            if(!action.payload || action.payload.length <= 0) {
                state.data = [];
                return;
            }

            state.data = action.payload.data;
            state.count = action.payload.count;
        }
    }
});

export const { profileRecordAction } = profileSlice.actions;

export default profileSlice.reducer;