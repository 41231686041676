import {Card, CardContent, Container, Grid, MenuItem, Select, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import {useNavigate, useParams} from 'react-router-dom';
import {IGiftCard} from "../../interface/IGiftCard";
import theme from "../../theme";
import useFetchRequest from "../../common/fetchRequest";
import { Alert } from "@mui/material";

const ProfileEditForm = () => {

    const { id }  = useParams();
    const [pending, setPending] = useState(false);
    const navigate = useNavigate();
    const [firstname, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [serverMessage, setServerMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [request, setRequest] = useState({});

    //snackbar
    const [open, setOpen] = useState(false);


    const callback = (data: any) => {
        setPending(false);
        setAlertColor('success');

        if(data && data.statusCode > 299) {
            setServerMessage(JSON.stringify(data.message ?? {}));
            setAlertColor('error');
        } else {
            setServerMessage('Profile edited successfully');
        }

        setOpen(true);

    };
    useFetchRequest( `${process.env.REACT_APP_API_URL}/admin/user/profiles`,
        request, 'PATCH', callback);

    const rowString = localStorage.getItem("profile") as any;
    const row: any = rowString ? JSON.parse(rowString) : null;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if(!row) return;

            if(row) {
                let names = row.fullName.split(' ');
                setFirstName(names[0] ?? '');
                setLastName(names[1] ?? '');
                setRole(row.role);
            }
        },500);

        return () => clearTimeout(timeout)
    }, []);


    if(!row) {
        navigate('/dashboard/profile/list', { replace: true })
        return (<></>);
    }

    const handleClose = (event: any, reason?: any) => {
        if (reason && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setPending(true);

        let request: any = {
            userId: parseInt(id as string),
            role: role,
            password: password,
            firstName: firstname,
            lastName: lastName
        };

        if(password === ''){
            delete request.password;
        }

        setRequest(request);

    };

    return (
        <Container sx={{marginTop: '50px'}}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h3'>Edit Profile</Typography>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3} columns={{sm:12, md:12, xs:6}}>
                                   
                                <Grid item xs={6}>
                                        <TextField
                                            id='firstname'
                                            label='First Name'
                                            value={firstname}
                                            fullWidth
                                            onChange={e => setFirstName(e.target.value)}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id='lastname'
                                            label='Last Name'
                                            value={lastName}
                                            fullWidth
                                            onChange={e => setLastName(e.target.value)}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id='password'
                                            label='Password'
                                            value={password}
                                            type='password'
                                            fullWidth
                                            onChange={e => setPassword(e.target.value)}
                                            
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="role"
                                            value={role}
                                            label="Role"
                                            fullWidth
                                            onChange={e => setRole(e.target.value)}
                                        >
                                            <MenuItem value='super'>Super Admin</MenuItem>
                                            <MenuItem value='admin'>Admin</MenuItem>
                                            <MenuItem value='staff'>Staff</MenuItem>
                                            <MenuItem value='accountant'>Accountant</MenuItem>
                                        </Select>

                                    </Grid>

                                    {!pending && <Grid item xs={12}>
                                        <Box paddingX={{
                                            xs:theme.spacing(2),
                                            md:theme.spacing(20),
                                            sm:theme.spacing(20)

                                        }} paddingY={4}>
                                            <Button sx={{width:'100%'}} size={"large"} type='submit' variant='contained'> Submit </Button>
                                        </Box>

                                    </Grid>}

                                    {pending && <Grid item xs={12}>
                                        <Box paddingX={{
                                            xs:theme.spacing(2),
                                            md:theme.spacing(20),
                                            sm:theme.spacing(20)

                                        }} paddingY={4}>
                                            <LoadingButton
                                                loading
                                                loadingPosition="start"
                                                startIcon={<SaveIcon />}
                                                variant="contained"
                                                fullWidth
                                            >
                                                Loading
                                            </LoadingButton>
                                        </Box>

                                    </Grid>}

                                </Grid>
                            </form>

                            <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal:'center'}}  autoHideDuration={6000} onClose={handleClose}>
                                <Alert severity={alertColor as any} onClose={handleClose} sx={{ width: '100%' }}>
                                    { serverMessage }
                                </Alert>
                            </Snackbar>

                        </Grid>


                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};

export default ProfileEditForm;
