import { Card, CardContent, Container, Grid, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { IGiftCard } from "../../interface/IGiftCard";
import theme from "../../theme";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import fetchCollections from "../../common/fetchCollections";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import SavingsIcon from "@mui/icons-material/Savings";

const DashboardView = () => {
  const navigate = useNavigate();

  const [successTrans, setSuccessTrans] = useState(0);
  const [failedTrans, setFailedTrans] = useState(0);
  const [totalTrans, setTotalTrans] = useState(0);
  const [allWallet, setAllWallet] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [giftCardTrans, setGiftCardTrans] = useState(0);
  const [billTrans, setBillTrans] = useState(0);
  const [cryptoTrans, setCryptoTrans] = useState(0);
  const [withdrawalTrans, setWithdrawalTrans] = useState(0);

  //fetch the record form the database
  fetchCollections(
    `${process.env.REACT_APP_API_URL}/admin/transaction/dashboard`,
    (result: any) => {
      if (result.data) {
        setSuccessTrans(result.data.data.successfulTransactionAmount);
        setFailedTrans(result.data.data.failedTransactionAmount);
        setTotalTrans(result.data.data.totalTransaction);
        setAllWallet(result.data.data.allWalletAmount);
        setTotalUser(result.data.data.totalUser);
        setGiftCardTrans(result.data.data.successfulGiftCardTransaction);
        setBillTrans(result.data.data.successfulBillTransaction);
        setWithdrawalTrans(result.data.data.successfulWithdrawalTransaction);
        setCryptoTrans(result.data.data.successfulCryptoTransaction);
      }
    }
  );

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="center"
                fontWeight="bold"
                variant="h5"
                component="h3"
              >
                Dashboard
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  {" "}
                  <LocalAtmIcon sx={{ color: "#76b476" }} />{" "}
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#76b476" }}
                  fontSize={theme.spacing(2)}
                >
                  {" "}
                  Successful Transactions{" "}
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  {" "}
                  &#8358;{`${successTrans}`}{" "}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  {" "}
                  <LocalAtmIcon sx={{ color: "#f55c6b" }} />{" "}
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#f55c6b" }}
                  fontSize={theme.spacing(2)}
                >
                  {" "}
                  Failed Transactions{" "}
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  {" "}
                  &#8358;{`${failedTrans}`}{" "}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  {" "}
                  <ContentCopyIcon sx={{ color: "#636df8" }} />{" "}
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#636df8" }}
                  fontSize={theme.spacing(2)}
                >
                  {" "}
                  Transaction Count{" "}
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  {" "}
                  {`${totalTrans}`}{" "}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  {" "}
                  <CreditScoreIcon sx={{ color: "#63f8e2" }} />{" "}
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#63f8e2" }}
                  fontSize={theme.spacing(2)}
                >
                  {" "}
                  Cumulative Wallet{" "}
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  {" "}
                  &#8358;{`${allWallet}`}{" "}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  {" "}
                  <SupportAgentIcon sx={{ color: "#25efa0" }} />{" "}
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#25efa0" }}
                  fontSize={theme.spacing(2)}
                >
                  {" "}
                  All Users{" "}
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  {" "}
                  {`${totalUser}`}{" "}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  <CardGiftcardIcon sx={{ color: "#ef7a6c" }} />
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#ef7a6c" }}
                  fontSize={theme.spacing(2)}
                >
                  Gift Card Transactions
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  &#8358;{giftCardTrans}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  <ReceiptIcon sx={{ color: "#8c9eff" }} />
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#8c9eff" }}
                  fontSize={theme.spacing(2)}
                >
                  Bill Transactions
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  &#8358;{billTrans}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  <SavingsIcon sx={{ color: "#76b476" }} />
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#76b476" }}
                  fontSize={theme.spacing(2)}
                >
                  Withdrawal Transactions
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  &#8358;{withdrawalTrans}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography align="center">
                  <CurrencyBitcoinIcon sx={{ color: "#76b476" }} />
                </Typography>
                <Typography
                  align="center"
                  fontWeight="bold"
                  sx={{ color: "#76b476" }}
                  fontSize={theme.spacing(2)}
                >
                  Crypto Transactions
                </Typography>

                <Typography align="center" fontSize={theme.spacing(4)}>
                  &#8358;{cryptoTrans}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default DashboardView;
