import * as React from "react";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import { IPaginationColumn } from "../../interface/IPaginationProps";
import { transactionRecordAction } from "../../reducer/transactionReducer";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const status: any = {
  success: "success",
  pending: "warning",
  failed: "error",
  "awaiting approval": "warning",
};

const columns: IPaginationColumn = {
  channel: { label: "Channel", align: "left", format: (v: any) => v },
  reference: { label: "Reference", align: "left", format: (v: any) => v },
  amount: { label: "Amount", align: "left", format: (v: any) => v },
  type: { label: "Type", format: (v: any) => v },
  user: { label: "Full Name", format: (v: any) => v?.fullName },
  status: {
    label: "Status",
    format: (v: any) => <Chip color={status[v]} label={v} />,
  },
  createdAt: {
    label: "Date",
    editable: false,
    format: (value: any) => moment(value).format("dddd, MMMM Do YYYY"),
  },
};

export default function Transaction() {
  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

  const rows = useSelector((state: any) => state.transaction.data);
  const count = useSelector((state: any) => state.transaction.count);
  const navigate = useNavigate();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value as string);
  };

  const handleReset = () => {
    setSearch("");
    setSelectedStatus("All");
    setSelectedDate(null);
  };

  const filteredRows = rows.filter((row: any) => {
    const createdAtDate = moment(row.createdAt).startOf("day");
    const filterDate = selectedDate ? selectedDate.startOf("day") : null;

    // Convert search string to lower case for case-insensitive search
    const searchLower = search.toLowerCase();

    return (
      (row.reference.toLowerCase().includes(searchLower) ||
        row.type.toLowerCase().includes(searchLower) ||
        row.user?.fullName.toLowerCase().includes(searchLower)) &&
      (selectedStatus === "All" ? true : row.status === selectedStatus) &&
      (!filterDate || createdAtDate.isSame(filterDate))
    );
  });

  const viewAction = (id: any, data: object) => {
    localStorage.setItem("transaction", JSON.stringify(data));
    navigate(`/dashboard/transaction/view/${id}`, { replace: true });
  };

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button variant="contained" onClick={handleReset}>
          Reset
        </Button>
      </Box>
      <TextField
        label="Search by Reference, Type, or Full Name"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        size="small"
        margin="normal"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
        }}
      >
        <FormControl margin="normal" style={{ minWidth: 120 }} size="small">
          <InputLabel>Status</InputLabel>
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            label="Status"
            autoWidth
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
            <MenuItem value="awaiting approval">Awaiting Approval</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Filter Date"
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            sx={{ width: "200px" }}
          />
        </LocalizationProvider>
      </Box>

      <PaginationRecord
        columns={columns}
        path="admin/transaction"
        recordAction={transactionRecordAction}
        data={filteredRows}
        dataSize={count}
        viewAction={viewAction}
        name="transaction"
      />
    </Box>
  );
}
