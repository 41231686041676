import { Card, CardContent, Container, Grid, MenuItem, Select, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useParams } from 'react-router-dom';
import { IGiftCard, IGiftCardCategory, IGiftCardCountry } from "../../interface/IGiftCard";
import theme from "../../theme";
import useFetchRequest from "../../common/fetchRequest";
import { Alert } from "@mui/material";
import fetchCollections from "../../common/fetchCollections";
import { useDispatch, useSelector } from "react-redux";
import { updateSettingAction } from "../../reducer/settingReducer";
import PasswordViewComponent from "./passwordViewComponent";

const SettingView = () => {

    const settingState = useSelector((state: any) => state.setting.data);
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    const [serverMessage, setServerMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [request, setRequest] = useState([]);

    //snackbar
    const [open, setOpen] = useState(false);

    const userRole = localStorage.getItem('role');
    const roles: any[] = ['super', 'admin', 'accountant'];
    const canClickRoles: any[] = ['super', 'admin']
    const canClick = canClickRoles.includes(userRole);

    //fetch the record form the database
    if (roles.includes(userRole)) {
        fetchCollections(`${process.env.REACT_APP_API_URL}/admin/transaction/get-properties`, (result: any) => {
            dispatch(updateSettingAction(result.data));
        });
    }


    const callback = (data: any) => {
        setPending(false);
        setAlertColor('success');

        if (data && data.statusCode > 299) {
            setServerMessage(JSON.stringify(data.message ?? {}));
            setAlertColor('error');
        } else {
            setServerMessage('Setting updated successfully');
            dispatch(updateSettingAction({ data: request }));
        }

        setOpen(true);

    };


    useFetchRequest(`${process.env.REACT_APP_API_URL}/admin/transaction/update-property`,
        request, 'PATCH', callback);



    const handleClose = (event: any, reason?: any) => {
        if (reason && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();


        setPending(true);

        const temRequest: any = [...settingState];

        setRequest(temRequest);

    };


    const changeSettingStateValue = (e: any, key: any) => {
        const updatedSetting = settingState.map((item: any) => {
            const oldState = { ...item };
            if (oldState.key === key) {
                oldState.value = e.target.value;
            }

            return oldState;
        });

        dispatch(updateSettingAction({ data: updatedSetting }));
    };

    return (
        <Container sx={{ marginTop: '50px' }}>
            <Card>
                <CardContent>

                    {roles.includes(userRole) &&
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography align='center' fontWeight='bold' variant='h5' component='h3'>Update Settings</Typography>
                            </Grid>

                            <Grid item xs={12} marginTop={4}>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3} columns={{ sm: 12, md: 12, xs: 6 }}>

                                        {
                                            settingState.map((state: any) => (
                                                <Grid key={state.key} item xs={6}>
                                                    <TextField
                                                        id={state.key}
                                                        label={state.key}
                                                        value={state.value}
                                                        fullWidth
                                                        onChange={e => changeSettingStateValue(e, state.key)}
                                                        required
                                                    />
                                                </Grid>
                                            ))
                                        }


                                        {!pending && canClick && <Grid item xs={12}>
                                            <Box paddingX={{
                                                xs: theme.spacing(2),
                                                md: theme.spacing(20),
                                                sm: theme.spacing(20)

                                            }} paddingY={4}>
                                                <Button sx={{ width: '100%' }} size={"large"} type='submit' variant='contained'> Submit </Button>
                                            </Box>

                                        </Grid>}

                                        {pending && canClick && <Grid item xs={12}>
                                            <Box paddingX={{
                                                xs: theme.spacing(2),
                                                md: theme.spacing(20),
                                                sm: theme.spacing(20)

                                            }} paddingY={4}>
                                                <LoadingButton
                                                    loading
                                                    loadingPosition="start"
                                                    startIcon={<SaveIcon />}
                                                    variant="contained"
                                                    fullWidth
                                                >
                                                    Loading
                                                </LoadingButton>
                                            </Box>

                                        </Grid>}

                                    </Grid>
                                </form>

                                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
                                    <Alert severity={alertColor as any} onClose={handleClose} sx={{ width: '100%' }}>
                                        {serverMessage}
                                    </Alert>
                                </Snackbar>

                            </Grid>


                        </Grid>
                    }

                    <PasswordViewComponent />


                </CardContent>
            </Card>
        </Container>
    );
};

export default SettingView;

