import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
};

const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    cryptoRecordAction: (state, action) => {
      if (!action.payload || action.payload.length <= 0) {
        state.data = [];
        state.count = 0;
        return;
      }

      state.data = action.payload.data;
      state.count = action.payload.count;
    },
  },
});

export const { cryptoRecordAction } = cryptoSlice.actions;

export default cryptoSlice.reducer;
