import { Card, CardContent, Container, Grid, MenuItem, Select, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useParams } from 'react-router-dom';
import { IGiftCard, IGiftCardCategory, IGiftCardCountry } from "../../interface/IGiftCard";
import theme from "../../theme";
import useFetchRequest from "../../common/fetchRequest";
import { Alert } from "@mui/material";
import fetchCollections from "../../common/fetchCollections";
import { useDispatch, useSelector } from "react-redux";
import { updateSettingAction } from "../../reducer/settingReducer";

const PasswordViewComponent = () => {
    const [pending, setPending] = useState(false);
    const [serverMessage, setServerMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [request, setRequest] = useState({});
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    //snackbar
    const [open, setOpen] = useState(false);

    const callback = (data: any) => {
        setPending(false);
        setAlertColor('success');

        if (data && data.statusCode > 299) {
            setServerMessage(JSON.stringify(data.message ?? {}));
            setAlertColor('error');
        } else {
            setServerMessage('Password updated successfully');
        }

        setOpen(true);

    };
    useFetchRequest(`${process.env.REACT_APP_API_URL}/user`,
        request, 'PATCH', callback);


    const handleClose = (event: any, reason?: any) => {
        if (reason && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();


        setPending(true);

        setRequest({
            oldPassword: oldPassword,
            newPassword: newPassword
        });

    };


    return (
        <Grid container spacing={3} marginTop={3}>
            <Grid item xs={12}>
                <Typography align='center' fontWeight='bold' variant='h5' component='h3'>Update Password</Typography>
            </Grid>

            <Grid item xs={12} marginTop={2}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} columns={{ sm: 12, md: 12, xs: 6 }}>

                        <Grid item xs={6}>
                            <TextField
                                id='oldPassword'
                                label='Old Password'
                                value={oldPassword}
                                type='password'
                                fullWidth
                                onChange={e => setOldPassword(e.target.value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                id='newPassword'
                                label='New Password'
                                value={newPassword}
                                type='password'
                                fullWidth
                                onChange={e => setNewPassword(e.target.value)}
                                required
                            />
                        </Grid>


                        {!pending && <Grid item xs={12}>
                            <Box paddingX={{
                                xs: theme.spacing(2),
                                md: theme.spacing(20),
                                sm: theme.spacing(20)

                            }} paddingY={4}>
                                <Button sx={{ width: '100%' }} size={"large"} type='submit' variant='contained'> Submit </Button>
                            </Box>

                        </Grid>}

                        {pending && <Grid item xs={12}>
                            <Box paddingX={{
                                xs: theme.spacing(2),
                                md: theme.spacing(20),
                                sm: theme.spacing(20)

                            }} paddingY={4}>
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    fullWidth
                                >
                                    Loading
                                </LoadingButton>
                            </Box>

                        </Grid>}

                    </Grid>
                </form>

                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
                    <Alert severity={alertColor as any} onClose={handleClose} sx={{ width: '100%' }}>
                        {serverMessage}
                    </Alert>
                </Snackbar>

            </Grid>


        </Grid>

    );
};

export default PasswordViewComponent;

