// Updated Crypto component
import * as React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import { IPaginationColumn } from "../../interface/IPaginationProps";
import { cryptoRecordAction } from "../../reducer/cryptoReducer";
import { useNavigate } from "react-router-dom";

const columns: IPaginationColumn = {
  cryptoName: {
    label: "Name",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },
  logoUrl: {
    label: "Logo URL",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },
  description: {
    label: "Description",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },
  rate: {
    label: "Rate",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },
  QRCode_imageUrl: {
    label: "QR Code URL",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },

  createdAt: {
    label: "Date",
    editable: false,
    format: (value: any) => moment(value).format("dddd, MMMM Do YYYY"),
  },
};

export default function Crypto() {
  const rows = useSelector((state: any) => state.crypto.data);
  const count = useSelector((state: any) => state.crypto.count);
  const role = useSelector((state: any) => state.auth.role);

  const navigate = useNavigate();

  const editAction = (id: any, data: object) => {
    localStorage.setItem("crypto", JSON.stringify(data));
    navigate(`/dashboard/crypto/edit/${id}`, { replace: true });
  };

  const viewAction = (id: any, data: object) => {
    localStorage.setItem("crypto", JSON.stringify(data));
    navigate(`/dashboard/crypto/view/${id}`, { replace: true });
  };

  const createAction = () => {
    navigate("/dashboard/crypto/create", { replace: true });
  };

  return (
    <PaginationRecord
      columns={columns}
      path="admin/admin-crypto"
      recordAction={cryptoRecordAction}
      data={rows}
      dataSize={count}
      editAction={role !== "staff" ? editAction : () => {}}
      deleteUrlPath={`${process.env.REACT_APP_API_URL}/admin/admin-crypto`}
      viewAction={viewAction}
      createAction={role !== "staff" ? createAction : () => {}}
      name="crypto"
    />
  );
}
