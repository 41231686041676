import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import theme from "../../theme";
import { ICrypto } from "../../interface/Icrypto";

const CryptoView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const rowString = localStorage.getItem("crypto");
  const row: ICrypto | null = rowString
    ? (JSON.parse(rowString) as ICrypto)
    : null;

  if (!row) {
    navigate("/dashboard/crypto/list", { replace: true });
    return null;
  }

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography align="center" fontWeight="bold" variant="h5">
                Crypto Details
              </Typography>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  Crypto Name
                </Typography>
                <Typography fontSize={theme.spacing(2)}>
                  {row.cryptoName}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  Wallet Address
                </Typography>
                <Typography fontSize={theme.spacing(2)}>
                  {row.walletAddress}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  Logo URL
                </Typography>
                <img
                  alt="Logo"
                  src={row.logoUrl}
                  style={{ maxWidth: "100%", height: "auto" }} // Added styling for image
                />
              </Paper>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  Description
                </Typography>
                <Typography fontSize={theme.spacing(2)}>
                  {row.description}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  Rate
                </Typography>
                <Typography fontSize={theme.spacing(2)}>{row.rate}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  QR Code Image URL
                </Typography>
                <img
                  alt="QR Code"
                  src={row.QRCode_imageUrl}
                  style={{ maxWidth: "100%", height: "auto" }} // Added styling for image
                />
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CryptoView;
