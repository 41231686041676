import {
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useParams } from "react-router-dom";
import theme from "../../theme";
import useFetchRequest from "../../common/fetchRequest";
import { Alert } from "@mui/material";

const CryptoCreateForm = () => {
  const { id } = useParams();
  const [pending, setPending] = useState(false);

  const [cryptoName, setCryptoName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState(0);
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState("");
  const [serverMessage, setServerMessage] = useState("");
  const [alertColor, setAlertColor] = useState("success");
  const [request, setRequest] = useState({});

  // Snackbar
  const [open, setOpen] = useState(false);

  const callback = (data: any) => {
    setPending(false);
    setAlertColor("success");

    if (data && data.statusCode > 299) {
      setServerMessage(JSON.stringify(data.message ?? {}));
      setAlertColor("error");
    } else {
      setServerMessage("Crypto created successfully");
    }

    setOpen(true);
  };

  useFetchRequest(
    `${process.env.REACT_APP_API_URL}/admin/admin-crypto`,
    request,
    "POST",
    callback
  );

  const handleClose = (event: any, reason?: any) => {
    if (reason && reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPending(true);

    setRequest({
      name: cryptoName,
      walletAddress: walletAddress,
      logoUrl: logoUrl,
      description: description,
      rate: rate,
      QRCode_imageUrl: qrCodeImageUrl,
    });
  };

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="center"
                fontWeight="bold"
                variant="h5"
                component="h3"
              >
                Create Crypto
              </Typography>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} columns={{ sm: 12, md: 12, xs: 6 }}>
                  <Grid item xs={6}>
                    <TextField
                      id="cryptoName"
                      label="Crypto Name"
                      value={cryptoName}
                      fullWidth
                      onChange={(e) => setCryptoName(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="walletAddress"
                      label="Wallet Address"
                      value={walletAddress}
                      fullWidth
                      onChange={(e) => setWalletAddress(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="logoUrl"
                      label="Logo URL"
                      value={logoUrl}
                      type="url"
                      fullWidth
                      onChange={(e) => setLogoUrl(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="description"
                      label="Description"
                      value={description}
                      fullWidth
                      multiline
                      rows={4}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="rate"
                      label="Rate"
                      value={rate}
                      type="number"
                      fullWidth
                      onChange={(e) => setRate(parseFloat(e.target.value))}
                      required
                      inputProps={{ min: 0 }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="qrCodeImageUrl"
                      label="QR Code Image URL"
                      value={qrCodeImageUrl}
                      type="url"
                      fullWidth
                      onChange={(e) => setQrCodeImageUrl(e.target.value)}
                      required
                    />
                  </Grid>

                  {!pending && (
                    <Grid item xs={12}>
                      <Box
                        paddingX={{
                          xs: theme.spacing(2),
                          md: theme.spacing(20),
                          sm: theme.spacing(20),
                        }}
                        paddingY={4}
                      >
                        <Button
                          sx={{ width: "100%" }}
                          size={"large"}
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  )}

                  {pending && (
                    <Grid item xs={12}>
                      <Box
                        paddingX={{
                          xs: theme.spacing(2),
                          md: theme.spacing(20),
                          sm: theme.spacing(20),
                        }}
                        paddingY={4}
                      >
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          fullWidth
                        >
                          Loading
                        </LoadingButton>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </form>

              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  severity={alertColor as any}
                  onClose={handleClose}
                  sx={{ width: "100%" }}
                >
                  {serverMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CryptoCreateForm;
