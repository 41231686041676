import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useState} from "react";
import {useDispatch} from "react-redux";
import {loginAction} from "../reducer/authReducer";
import {useNavigate} from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://unicxchange.co">
                UnicXchange
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {
    const [ isLogin, setIsLogin ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);

        fetch(`${process.env.REACT_APP_API_URL}/auth/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: data.get('username'),
                    password: data.get('password')
                }),

            }).then((res) => {
             if(!res.ok){
                throw new Error('server has issues connecting');
             }
            return res.json();
        })
            .then((data) => {
                setIsLoading(false);
                const roles = ['super','admin','staff','accountant'];
                if(data.token && roles.includes(data.user.role)) {
                    setIsLogin(true);
                    dispatch(loginAction({token:data.token, role:data.user.role}));
                    navigate('/dashboard/setting/list', { replace: true });
                }

            })
            .catch((error)=>{
                setIsLogin(false);
                setIsLoading(false);
            });


        /*
        console.log({
            email: data.get('username'),
            password: data.get('password'),
        });*/
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    { !isLogin && <Typography sx={{ color: 'red', fontSize:12 }}> Wrong username or password </Typography>}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    { !isLoading && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }} >
                        Sign In
                    </Button> }

                    { isLoading && <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                fullWidth
                            >
                                Loading
                            </LoadingButton>


                    }

                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
