import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: localStorage.getItem("token") ? true : false,
  token: localStorage.getItem("token"),
  role: localStorage.getItem("role"),

};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    loginAction: (state, action) => {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("role", action.payload.role);
      state.isLogin = true;
      state.token = action.payload.token;
      state.role = action.payload.role;
    },
    logoutAction: (state) => {
      localStorage.clear();
      state.isLogin = false;
      state.token = null;
      state.role = null;
    },
  },
});

export const { loginAction, logoutAction } = authSlice.actions;

export default authSlice.reducer;
