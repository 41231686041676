import * as React from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import {IPaginationColumn} from "../../interface/IPaginationProps";
import {giftCardRecordAction} from "../../reducer/giftCardReducer";
import {useNavigate} from "react-router-dom";
import { profileRecordAction } from '../../reducer/profileReducer';

const columns:IPaginationColumn  = {
    //id: { label: 'ID', align: 'left', editable: false, format: (v:any) => v },
    fullName: { label: 'Full Name', align: 'left', editable: true, type:'TextField', format: (v:any) => v },
    email: { label: 'Email', align: 'left', editable: true, type:'TextField', format: (v:any) => v },
    role: { label: 'Role', align: 'left', editable: true, type:'TextField', format: (v:any) => v },
    createdAt: { label: 'Date', editable: false, format: (value: any) =>  moment(value).format("dddd, MMMM Do YYYY") },
};

//{ id: 'time', label: 'Time', format: (value) =>  moment(value).format("h:mm:ss a") },

export default function Profile() {

    const rows = useSelector((state: any) => state.profile.data );
    const count = useSelector((state: any) => state.profile.count );
    const navigate = useNavigate();

    const editAction = (id: any, data: object) => {
        localStorage.setItem('profile', JSON.stringify(data));
        navigate(`/dashboard/profile/edit/${id}`, {replace: true});
    };

    const createAction = () => {
        navigate('/dashboard/profile/create', {replace: true});
    };


    return (
        <PaginationRecord columns={columns} path='admin/user/profiles'
                          recordAction={profileRecordAction} data={rows}
                          dataSize={count}
                          editAction={editAction}
                          deleteUrlPath={`${process.env.REACT_APP_API_URL}/admin/user/profiles`}
                          createAction={createAction}
                          name='profile'
        />
    );
}