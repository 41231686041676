import {Card, CardContent, Container, Grid, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from 'react-router-dom';
import {IGiftCard, IGiftCardCategory, IGiftCardCountry} from "../../interface/IGiftCard";
import theme from "../../theme";
import Paper from "@mui/material/Paper";

const UserView = () => {

    const navigate = useNavigate();

    const rowString = localStorage.getItem("user") as any;
    const row = rowString ? JSON.parse(rowString) : null;

    const columnOneSize = 2;
    const columnTwoSize = 10;

    if(!row) {
        navigate('/dashboard/user/list', { replace: false })
        return (<></>);
    }

    return (
        <Container sx={{marginTop: '50px'}}>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h3'>User Details</Typography>
                        </Grid>

                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Full Name: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.fullName} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Email: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.email} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Username: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.username} </Typography>
                        </Grid>

                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Phone: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.phone} </Typography>
                        </Grid>

                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Referral Code: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.referralCode} </Typography>
                        </Grid>

                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> From Referral: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.fromReferral} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> From Where: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.fromWhere} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Wallet Amount: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> &#8358; {row.walletAmount} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Is Email Verified: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.emailVerifyDate ? 'YES': 'NO'} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Is Phone Verified: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.phoneVerifyDate ? 'YES': 'NO'} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Is BVN Verified: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.bvnVerifyDate ? 'YES': 'NO'} </Typography>
                        </Grid>


                        <Grid item xs={columnOneSize}>
                            <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Total Point: </Typography>
                        </Grid>

                        <Grid item xs={columnTwoSize}>
                            <Typography fontSize={theme.spacing(2)}> {row.totalPoint} </Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h5'>Virtual Accounts</Typography>
                        </Grid>

                        {
                            row.vanEntities && row.vanEntities.map((item: any) => (
                                <Grid key={item.id} container spacing={4} padding={4}>
                                    <Grid item xs={columnOneSize}>
                                        <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Provider: </Typography>
                                    </Grid>

                                    <Grid item xs={columnTwoSize}>
                                        <Typography fontSize={theme.spacing(2)}> {item.provider} </Typography>
                                    </Grid>

                                    <Grid item xs={columnOneSize}>
                                        <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Bank Name: </Typography>
                                    </Grid>

                                    <Grid item xs={columnTwoSize}>
                                        <Typography fontSize={theme.spacing(2)}> {item.bankName} </Typography>
                                    </Grid>

                                    <Grid item xs={columnOneSize}>
                                        <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Account Number: </Typography>
                                    </Grid>

                                    <Grid item xs={columnTwoSize}>
                                        <Typography fontSize={theme.spacing(2)}> {item.accountNumber} </Typography>
                                    </Grid>

                                    <Grid item xs={columnOneSize}>
                                        <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Account Name: </Typography>
                                    </Grid>

                                    <Grid item xs={columnTwoSize}>
                                        <Typography fontSize={theme.spacing(2)}> {item.accountName} </Typography>
                                    </Grid>
                                </Grid>
                            ))
                        }

                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h5'>Person Bank Detail</Typography>
                        </Grid>

                        {
                            row.bankEntities && row.bankEntities.map((item: any) => (
                                <Grid key={item.id} container spacing={4} padding={4}>

                                    <Grid item xs={columnOneSize}>
                                        <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Bank Name: </Typography>
                                    </Grid>

                                    <Grid item xs={columnTwoSize}>
                                        <Typography fontSize={theme.spacing(2)}> {item.bankName} </Typography>
                                    </Grid>

                                    <Grid item xs={columnOneSize}>
                                        <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Account Number: </Typography>
                                    </Grid>

                                    <Grid item xs={columnTwoSize}>
                                        <Typography fontSize={theme.spacing(2)}> {item.accountNumber} </Typography>
                                    </Grid>

                                    <Grid item xs={columnOneSize}>
                                        <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Account Name: </Typography>
                                    </Grid>

                                    <Grid item xs={columnTwoSize}>
                                        <Typography fontSize={theme.spacing(2)}> {item.accountName} </Typography>
                                    </Grid>
                                </Grid>
                            ))
                        }

                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};

export default UserView;
