import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetchRequest from "../../common/fetchRequest";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import theme from "../../theme";

const CryptoRequestView = () => {
  const [open, setOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [comment, setComment] = useState("");
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [serverMessage, setServerMessage] = useState("");
  const [alertColor, setAlertColor] = useState("success");
  const [request, setRequest] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const userRole = localStorage.getItem("role");
  const roles: any[] = ["super", "admin", "staff"];

  // Retrieve data from localStorage
  const rowString = localStorage.getItem("cryptoRequest") as string;
  const row: any = JSON.parse(rowString);

  useEffect(() => {
    if (row && row.finalAmount) {
      setFinalAmount(Number(row.finalAmount));
    }
  }, [row]);

  const callback = (data: any) => {
    setPending(false);
    if (data && data.statusCode > 299) {
      setServerMessage(JSON.stringify(data.message ?? {}));
      setAlertColor("error");
    } else {
      setServerMessage("Crypto request updated successfully");
      setShowForm(false);
    }
    setOpen(true);
  };

  useFetchRequest(
    `${process.env.REACT_APP_API_URL}/admin/admin-crypto/crypto-request/approve`,
    request,
    "PATCH",
    callback
  );

  const handleClose = (event: any, reason?: any) => {
    if (reason && reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (event: any, clickStatus: string) => {
    event?.preventDefault();
    setPending(true);
    setRequest({
      id: row.id,
      isApprove: clickStatus === "accepted",
      amountToCredit: finalAmount,
      rejectedReason: comment,
    });
  };

  if (!row) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="center"
                fontWeight="bold"
                variant="h5"
                component="h3"
              >
                CRYPTO REQUEST
              </Typography>
            </Grid>
            <Grid item xs={5} marginTop={5}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography fontWeight="bold">Full Name</Typography>
                    }
                    secondary={row.user.fullName}
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography fontWeight="bold">Dollar Amount</Typography>
                    }
                    secondary={row.dollarAmount}
                  />
                </ListItem>

                <Divider />
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography fontWeight="bold">Final Amount</Typography>
                    }
                    secondary={row.finalAmount}
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography fontWeight="bold">Description</Typography>
                    }
                    secondary={row.description}
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={<Typography fontWeight="bold">Status</Typography>}
                    secondary={row.status}
                  />
                </ListItem>

                {row.status === "rejected" && row.rejectionReason && (
                  <>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography fontWeight="bold">
                            Rejected Reason
                          </Typography>
                        }
                        secondary={row.rejectionReason}
                      />
                    </ListItem>
                  </>
                )}
              </List>
            </Grid>
            <Grid item xs={7} marginTop={3}>
              <ImageList
                sx={{ width: 500, height: 450 }}
                cols={1}
                rowHeight={200}
              >
                {row.cryptoRequestImageEntities.map((image: any) => (
                  <ImageListItem key={image.imageUrl}>
                    <img
                      srcSet={`${process.env.REACT_APP_API_URL}/${image.imageUrl}`}
                      src={`${process.env.REACT_APP_API_URL}/${image.imageUrl}`}
                      alt={"request images"}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
            {roles.includes(userRole) &&
              showForm &&
              row.status === "in-progress" && (
                <form>
                  <Grid item xs={12} padding={5}>
                    <TextField
                      type="number"
                      label="Final Amount"
                      value={finalAmount}
                      onChange={(e) =>
                        setFinalAmount(parseFloat(e.target.value))
                      }
                      fullWidth
                      inputProps={{ min: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} padding={5}>
                    <TextField
                      id="comment"
                      label="Rejected Reason (optional)"
                      value={comment}
                      type="textarea"
                      fullWidth
                      multiline
                      maxRows={4}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Grid>

                  {!pending && (
                    <Grid container item xs={12}>
                      <Grid item xs={6}>
                        <Box
                          paddingX={{
                            xs: theme.spacing(2),
                            md: theme.spacing(20),
                            sm: theme.spacing(20),
                          }}
                          paddingY={4}
                        >
                          <Button
                            sx={{ width: "100%" }}
                            onClick={(e) => handleSubmit(e, "accepted")}
                            size={"large"}
                            variant="contained"
                          >
                            {" "}
                            APPROVE{" "}
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          paddingX={{
                            xs: theme.spacing(2),
                            md: theme.spacing(20),
                            sm: theme.spacing(20),
                          }}
                          paddingY={4}
                        >
                          <Button
                            sx={{ width: "100%" }}
                            onClick={(e) => handleSubmit(e, "rejected")}
                            color="error"
                            size={"large"}
                            variant="contained"
                          >
                            {" "}
                            REJECT{" "}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {pending && (
                    <Grid item xs={12}>
                      <Box paddingX={2} paddingY={4}>
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          fullWidth
                        >
                          Loading
                        </LoadingButton>
                      </Box>
                    </Grid>
                  )}
                </form>
              )}
            <Snackbar
              open={open}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                severity={alertColor as any}
                onClose={handleClose}
                sx={{ width: "100%" }}
              >
                {serverMessage}
              </Alert>
            </Snackbar>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CryptoRequestView;
