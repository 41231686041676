import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Skeleton,
  Stack,
  styled,
  tableCellClasses,
  TableHead,
  Grid,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import fetchCollections from "../common/fetchCollections";
import { useState } from "react";
import theme from "../theme";
import PropTypes from "prop-types";
import { IPaginationProps } from "../interface/IPaginationProps";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import useFetchRequest from "../common/fetchRequest";

function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function PaginationRecord({
  columns,
  path,
  recordAction,
  data,
  dataSize,
  editAction,
  deleteUrlPath,
  name,
  viewAction,
  createAction,
}: IPaginationProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const dispatch = useDispatch();
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_API_URL}/${path}?page=${
      page + 1
    }&limit=${rowsPerPage}`
  );
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [request, setRequest] = useState({});
  const [serverMessage, setServerMessage] = useState("");
  const [alertColor, setAlertColor] = useState("success");
  const [deleteId, setDeleteId] = useState("0");

  //snackbar
  const [open, setOpen] = useState(false);

  //dialog
  const [openDialog, setOpenDialog] = useState(false);

  const rows = data;
  const count = dataSize;

  //fetch the record form the database
  fetchCollections(url, (data: any) => {
    dispatch(recordAction(data));
    setPending(false);
  });

  const callback = (data: any) => {
    setPending(false);
    setAlertColor("success");

    if (data && data.statusCode > 299) {
      setServerMessage(JSON.stringify(data.message ?? {}));
      setAlertColor("error");
    } else {
      setServerMessage("Item deleted successfully");
      navigate(0);
    }

    setOpen(true);
  };

  //for removing
  useFetchRequest(`${deleteUrlPath}/${deleteId}`, request, "DELETE", callback);

  //for snackbar and alert
  const handleClose = (event: any, reason?: any) => {
    if (reason && reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleChangePage = (event: any, newPage: any) => {
    setPending(true);
    setPage(newPage);
    setUrl(
      `${process.env.REACT_APP_API_URL}/${path}?page=${
        newPage + 1
      }&limit=${rowsPerPage}`
    );
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPending(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUrl(
      `${process.env.REACT_APP_API_URL}/${path}?page=${1}&limit=${
        event.target.value
      }`
    );
  };

  const deleteItem = () => {
    setOpenDialog(false);
    setPending(true);
    setRequest({ id: deleteId });
  };

  const openDialogBox = (id: any) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {createAction && (
        <Grid container spacing={3} direction="row" justifyContent="flex-end">
          <Grid item sx={{ padding: 2 }}>
            <Button onClick={createAction} variant="contained">
              Create
            </Button>
          </Grid>
        </Grid>
      )}
      {!pending && (
        <TableContainer sx={{ maxHeight: theme.spacing(100) }}>
          <Table
            stickyHeader={true}
            sx={{ minWidth: 500 }}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell key="serialNumber"> SN </StyledTableCell>
                {Object.keys(columns).map((column) => (
                  <StyledTableCell
                    key={column}
                    align={columns[column].align ?? "left"}
                  >
                    {columns[column].label}
                  </StyledTableCell>
                ))}

                {viewAction && (
                  <StyledTableCell key="viewItem"> Display </StyledTableCell>
                )}
                {editAction && (
                  <StyledTableCell key="editItem"> Modify </StyledTableCell>
                )}
                {deleteUrlPath && (
                  <StyledTableCell key="deleteItem"> Remove </StyledTableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row: any, index: number) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    key={`sn_${row.id * (Math.random() * 100)}`}
                    component="th"
                    scope="row"
                  >
                    <Typography
                      maxWidth={200}
                      component="div"
                      fontSize={14}
                      noWrap={true}
                    >
                      {" "}
                      {page * rowsPerPage + index + 1}{" "}
                    </Typography>
                  </StyledTableCell>

                  {Object.keys(columns).map((item, index) => {
                    if (!columns[item]) return <></>;
                    return (
                      <StyledTableCell key={index} component="th" scope="row">
                        <Typography
                          maxWidth={200}
                          component="div"
                          fontSize={14}
                          noWrap={true}
                        >
                          {" "}
                          {columns[item].format(row[item])}{" "}
                        </Typography>
                      </StyledTableCell>
                    );
                  })}

                  {viewAction && (
                    <StyledTableCell
                      key={row.id * (Math.random() * 100)}
                      component="th"
                      scope="row"
                    >
                      <Button
                        color="success"
                        size="small"
                        onClick={() => viewAction(row.id, row)}
                      >
                        {" "}
                        View{" "}
                      </Button>
                    </StyledTableCell>
                  )}

                  {editAction && (
                    <StyledTableCell
                      key={row.id * (Math.random() * 100)}
                      component="th"
                      scope="row"
                    >
                      <Button
                        color="info"
                        size="small"
                        onClick={() => editAction(row.id, row)}
                      >
                        {" "}
                        Edit{" "}
                      </Button>
                    </StyledTableCell>
                  )}

                  {deleteUrlPath && (
                    <StyledTableCell
                      key={row.id * (Math.random() * 100)}
                      component="th"
                      scope="row"
                    >
                      <Button
                        color="error"
                        size="small"
                        onClick={() => openDialogBox(row.id)}
                      >
                        {" "}
                        Delete{" "}
                      </Button>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      {pending && (
        <Stack spacing={3}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={theme.spacing(19)}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={theme.spacing(25)}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={theme.spacing(5)}
          />
        </Stack>
      )}

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={alertColor as any}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {serverMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} keepMounted onClose={() => {}}>
        <DialogTitle>{"Remove Item"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to remove this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>No</Button>
          <Button onClick={deleteItem}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
