import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import theme from "../../theme";

interface ICurrencyRate {
  id: number;
  name: string;
  rateToNaira: number;
}

const CurrencyRateView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const rowString = localStorage.getItem("currencyRate");
  const row: ICurrencyRate | null = rowString
    ? (JSON.parse(rowString) as ICurrencyRate)
    : null;

  if (!row) {
    navigate("/dashboard/currency-rates/list", { replace: true });
    return null;
  }

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography align="center" fontWeight="bold" variant="h5">
                Currency Rate Details
              </Typography>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  Currency Name
                </Typography>
                <Typography fontSize={theme.spacing(2)}>{row.name}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography fontWeight="bold" fontSize={theme.spacing(2)}>
                  Rate to Naira
                </Typography>
                <Typography fontSize={theme.spacing(2)}>
                  {row.rateToNaira}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CurrencyRateView;
