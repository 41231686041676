import { Navigate, Outlet, useMatch, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import Logo from "../asset/unicxchange.jpeg";
import { useDispatch } from "react-redux";
import { logoutAction } from "../reducer/authReducer";
import LogoutIcon from "@mui/icons-material/Logout";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LanguageIcon from "@mui/icons-material/Language";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ComputerIcon from "@mui/icons-material/Computer";
import SettingsIcon from "@mui/icons-material/Settings";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import CryptoIcon from "@mui/icons-material/MonetizationOn";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function ProtectedComponent() {
  const isMatch = useMatch("/dashboard");
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("Dashboard");

  const userRole = localStorage.getItem("role");

  if (isMatch) {
    return <Navigate to="/dashboard/setting/list" replace={true}></Navigate>;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    dispatch(logoutAction());
    navigate("/", { replace: true });
  };

  const menus = [
    {
      label: "Dashboard",
      icon: <ComputerIcon />,
      roles: ["super", "admin", "accountant"],
      click: () => {
        setTitle("Dashboard");
        navigate("/dashboard/dashboard/list");
      },
    },
    {
      label: "Users",
      icon: <PeopleOutlineIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("All Users");
        navigate("/dashboard/user/list");
      },
    },
    {
      label: "Gift Card Request",
      icon: <SupportAgentIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("All Gift Card Request");
        navigate("/dashboard/gift-card-status/list");
      },
    },
    {
      label: "Transactions",
      icon: <LocalAtmIcon />,
      roles: ["super", "admin", "accountant"],
      click: () => {
        setTitle("Transactions");
        navigate("/dashboard/transaction/list");
      },
    },
    {
      label: "Crypto",
      icon: <CryptoIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("Crypto");
        navigate("/dashboard/crypto/list");
      },
    },

    {
      label: "Crypto Requests",
      icon: <RequestQuoteIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("Crypto Requests");
        navigate("/dashboard/crypto-request/list");
      },
    },
    {
      label: "Currency Rate",
      icon: <CurrencyExchangeIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("Currency Rate");
        navigate("/dashboard/currency-rate/list");
      },
    },

    {
      label: "Gift Card",
      icon: <CardGiftcardIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("Gift Card");
        navigate("/dashboard/gift-card/list");
      },
    },
    {
      label: "Gift Card Country",
      icon: <LanguageIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("Gift Card Country");
        navigate("/dashboard/gift-card-country/list");
      },
    },
    {
      label: "Gift Categories",
      icon: <ListAltIcon />,
      roles: ["super", "admin", "staff"],
      click: () => {
        setTitle("Gift Categories");
        navigate("/dashboard/gift-card-category/list");
      },
    },
    {
      label: "Profiles",
      icon: <CoPresentIcon />,
      roles: ["super"],
      click: () => {
        setTitle("Profiles");
        navigate("/dashboard/profile/list");
      },
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      roles: ["super", "admin", "staff", "accountant"],
      click: () => {
        setTitle("Settings");
        navigate("/dashboard/setting/list");
      },
    },

    {
      label: "Logout",
      icon: <LogoutIcon />,
      roles: ["super", "admin", "staff", "accountant"],
      click: () => logout(),
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            alt="Logo"
            src={Logo}
            style={{
              width: theme.spacing(10),
              height: theme.spacing(10),
              padding: "10px",
            }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menus.map(
            (item) =>
              item.roles.includes(userRole) && (
                <ListItem
                  key={item.label}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={item.click}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
