import { Alert, Box, Button, Card, CardContent, Container, Divider, Grid, ImageList, ImageListItem, List, ListItem, ListItemText, Snackbar, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from 'react-router-dom';
import { IGiftCard } from "../../interface/IGiftCard";
import theme from "../../theme";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import useFetchRequest from "../../common/fetchRequest";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';

const GiftCardStatusView = () => {

    //snackbar
    const [open, setOpen] = useState(false);

    const { id } = useParams();
    const [pending, setPending] = useState(false);
    const [status, setStatus] = useState('cancelled');
    const [showForm, setShowForm] = useState(true);
    const [comment, setComment] = useState('');
    const [serverMessage, setServerMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [request, setRequest] = useState({});
    const navigate = useNavigate();

    const userRole = localStorage.getItem('role');
    const roles: any[] = ['super', 'admin'];

    const callback = (data: any) => {
        setPending(false);
        setAlertColor('success');

        if (data && data.statusCode > 299) {
            setServerMessage(JSON.stringify(data.message ?? {}));
            setAlertColor('error');
        } else {
            setServerMessage('Gift card status updated successfully');
            setShowForm(false);
        }

        setOpen(true);

    };


    useFetchRequest(`${process.env.REACT_APP_API_URL}/admin/user/toggle-gift-status`,
        request, 'POST', callback);


    const rowString = localStorage.getItem("giftCardStatus") as any;
    const row: any = JSON.parse(rowString);

    if (!row) {
        navigate('/dashboard/gift-card/list', { replace: true })
        return (<></>);
    }


    const handleClose = (event: any, reason?: any) => {
        if (reason && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (event: any, clickStatus: string) => {
        event?.preventDefault();
        setPending(true);


        setRequest({
            status: clickStatus,
            comment: comment,
            id: row.id
        });

    };

    return (
        <Container sx={{ marginTop: '50px' }}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h3'>GIFT CARD REQUEST</Typography>
                        </Grid>

                        <Grid item xs={5} marginTop={5}>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Full Name</Typography>} secondary={row.user.fullName} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Quantity</Typography>} secondary={row.quantity} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Unit Price</Typography>}
                                        secondary={row.unitPrice} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Total Amount</Typography>}
                                        secondary={row.totalAmount} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Status</Typography>}
                                        secondary={row.status} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Card Type</Typography>}
                                        secondary={row.giftCardTypeEntity?.giftCardCountryEntity?.giftCardEntity?.name} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Card Country</Typography>}
                                        secondary={row.giftCardTypeEntity?.giftCardCountryEntity?.name} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Card Name</Typography>}
                                        secondary={row.giftCardTypeEntity?.name} />
                                </ListItem>

                                <Divider />

                            </List>
                        </Grid>

                        <Grid item xs={7} marginTop={3}>
                            <ImageList sx={{ width: 500, height: 450 }} cols={1} rowHeight={200}>
                                {row.resourceUrl.split(',').map((item: any) => (
                                    <ImageListItem key={item}>
                                        <img
                                            srcSet={`${process.env.REACT_APP_API_URL}/${item}`}
                                            src={`${process.env.REACT_APP_API_URL}/${item}`}
                                            alt={'status images'}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                        {
                            roles.includes(userRole) && showForm && row.status === 'pending' && <form /*onSubmit={handleSubmit}*/ >

                                <Grid item xs={12} padding={10}>
                                    <TextField
                                        id='comment'
                                        label='Comment'
                                        value={comment}
                                        type='textarea'
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        onChange={e => setComment(e.target.value)}
                                    />
                                </Grid>
                                {!pending && <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <Box paddingX={{
                                            xs: theme.spacing(2),
                                            md: theme.spacing(20),
                                            sm: theme.spacing(20)

                                        }} paddingY={4}>
                                            <Button sx={{ width: '100%' }} onClick={() => handleSubmit(this,'success')} size={"large"} variant='contained'> APPROVE </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box paddingX={{
                                            xs: theme.spacing(2),
                                            md: theme.spacing(20),
                                            sm: theme.spacing(20)

                                        }} paddingY={4}>
                                            <Button sx={{ width: '100%' }} onClick={() => handleSubmit(this,'cancelled')} color="error" size={"large"} variant='contained'> REJECT </Button>
                                        </Box>
                                    </Grid>

                                </Grid>}

                                {pending && <Grid item xs={12}>
                                    <Box paddingX={{
                                        xs: theme.spacing(2),
                                        md: theme.spacing(20),
                                        sm: theme.spacing(20)

                                    }} paddingY={4}>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="contained"
                                            fullWidth
                                        >
                                            Loading
                                        </LoadingButton>
                                    </Box>

                                </Grid>}
                            </form>
                        }

                            <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal:'center'}}  autoHideDuration={6000} onClose={handleClose}>
                                <Alert severity={alertColor as any} onClose={handleClose} sx={{ width: '100%' }}>
                                    { serverMessage }
                                </Alert>
                            </Snackbar>

                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};

export default GiftCardStatusView;
