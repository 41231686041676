import * as React from "react";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import { IPaginationColumn } from "../../interface/IPaginationProps";
import { cryptoRequestAction } from "../../reducer/cryptoRequestReducer";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const statusColors: Record<string, "warning" | "success" | "error"> = {
  "in-progress": "warning",
  accepted: "success",
  rejected: "error",
};

const columns: IPaginationColumn = {
  description: { label: "Description", align: "left", format: (v: any) => v },
  dollarAmount: {
    label: "Dollar Amount",
    align: "left",
    format: (v: any) => v,
  },
  finalAmount: { label: "Final Amount", align: "left", format: (v: any) => v },
  user: { label: "Full Name", format: (v: any) => v?.fullName },
  status: {
    label: "Status",
    format: (v: any) => {
      const capitalizedStatus = v.charAt(0).toUpperCase() + v.slice(1);
      return <Chip color={statusColors[v]} label={capitalizedStatus} />;
    },
  },
  createdAt: {
    label: "Date Created",
    editable: false,
    format: (value: any) => moment(value).format("dddd, MMMM Do YYYY"),
  },
};

export default function CryptoRequest() {
  const [statusFilter, setStatusFilter] = useState("All");
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

  const rows = useSelector((state: any) => state.cryptoRequest.data);
  const count = useSelector((state: any) => state.cryptoRequest.count);
  const navigate = useNavigate();

  const viewAction = (id: any, data: object) => {
    localStorage.setItem("cryptoRequest", JSON.stringify(data));
    navigate(`/dashboard/crypto-request/view/${id}`, { replace: true });
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleReset = () => {
    setSearch("");
    setStatusFilter("All");
    setSelectedDate(null);
  };

  const filteredRows = rows.filter((row: any) => {
    const createdAtDate = moment(row.createdAt).startOf("day");
    const filterDate = selectedDate ? selectedDate.startOf("day") : null;

    return (
      (statusFilter === "All" ? true : row.status === statusFilter) &&
      row.user?.fullName?.toLowerCase().includes(search.toLowerCase()) &&
      (!filterDate || createdAtDate.isSame(filterDate))
    );
  });

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      {/* Reset Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button variant="contained" onClick={handleReset}>
          Reset
        </Button>
      </Box>

      {/* Search Field */}
      <TextField
        label="Search by Customer Name"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        size="small"
        sx={{ mb: 1 }}
        margin="normal"
      />

      {/* Filters for Status and Date */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
        }}
      >
        <FormControl margin="normal" style={{ minWidth: 120 }} size="small">
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Status"
            autoWidth
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="in-progress">In Progress</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Filter Date"
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            sx={{ width: "200px" }}
          />
        </LocalizationProvider>
      </Box>

      {/* Pagination Component */}
      <PaginationRecord
        columns={columns}
        path="admin/admin-crypto/crypto-request"
        recordAction={cryptoRequestAction}
        data={filteredRows}
        dataSize={count}
        viewAction={viewAction}
        name="cryptoRequest"
      />
    </Box>
  );
}
