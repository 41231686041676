import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    count: 0
}

const giftCardStatusSlice = createSlice({
    name: "giftCardStatus",
    initialState,
    reducers: {
        giftCardStatusRecordAction: (state, action) => {
            if(!action.payload || action.payload.length <= 0) {
                state.data = [];
                return;
            }

            state.data = action.payload.data;
            state.count = action.payload.count;
        }
    }
});

export const { giftCardStatusRecordAction } = giftCardStatusSlice.actions;

export default giftCardStatusSlice.reducer;