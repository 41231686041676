import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
};

const cryptoRequestSlice = createSlice({
  name: "cryptoRequest",
  initialState,
  reducers: {
    // The action name should match what's used in the component
    cryptoRequestAction: (state, action) => {
      if (!action.payload || action.payload.length <= 0) {
        state.data = [];
        return;
      }

      state.data = action.payload.data;
      state.count = action.payload.count;
    },
  },
});

export const { cryptoRequestAction } = cryptoRequestSlice.actions;

export default cryptoRequestSlice.reducer;
