import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducer/authReducer";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { RouterProvider } from "react-router-dom";
import router from "./routes/mainRoute";
import giftCardReducer from "./reducer/giftCardReducer";
import giftCardCountryReducer from "./reducer/giftCardCountryReducer";
import giftCardCategoryReducer from "./reducer/giftCardCategoryReducer";
import userReducer from "./reducer/userReducer";
import transactionReducer from "./reducer/transactionReducer";
import giftCardStatusReducer from "./reducer/giftCardStatusReducer";
import settingReducer from "./reducer/settingReducer";
import profileReducer from "./reducer/profileReducer";
import cryptoReducer from "./reducer/cryptoReducer";
import cryptoRequestReducer from "./reducer/cryptoRequestReducer";
import currencyRateReducer from "./reducer/currencyRateReducer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = configureStore({
  reducer: {
    auth: authReducer,
    giftCard: giftCardReducer,
    giftCardCountry: giftCardCountryReducer,
    giftCardCategory: giftCardCategoryReducer,
    user: userReducer,
    transaction: transactionReducer,
    giftCardStatus: giftCardStatusReducer,
    setting: settingReducer,
    profile: profileReducer,
    crypto: cryptoReducer,
    cryptoRequest: cryptoRequestReducer,
    currencyRate: currencyRateReducer,
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
