import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../reducer/authReducer";

const useFetchRequest = (
  url: any,
  request: any,
  method: "POST" | "PUT" | "PATCH" | "DELETE",
  action: (value: any) => void
) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    const abortController = new AbortController();

    if (request.length > 0 || Object.keys(request).length !== 0) {
      fetch(url, {
        signal: abortController.signal,
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: !token ? "" : "Bearer " + token,
        },
        body: JSON.stringify(request),
      })
        .then((res) => {
          if (!res.ok && res.status == 401) {
            if (res.status == 401) {
              //logout the user possibly forbidden
              dispatch(logoutAction());
              navigate("/");
              throw new Error("authentication error");
            }
          }

          return res.json();
        })

        .then((data) => {
          //   console.log(data);
          action(data);
        })
        .catch((e) => {
          action({
            statusCode: 400,
            message: e.message,
          });
        });
    }
    return () => {
      abortController.abort();
    };
  }, [request]);
};

export default useFetchRequest;
