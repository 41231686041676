import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    count: 0
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        userRecordAction: (state, action) => {
            if(!action.payload || action.payload.length <= 0) {
                state.data = [];
                return;
            }

            state.data = action.payload.data;
            state.count = action.payload.count;
        }
    }
});

export const { userRecordAction } = userSlice.actions;

export default userSlice.reducer;