import * as React from "react";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaginationRecord from "../../component/paginationRecord";
import { IPaginationColumn } from "../../interface/IPaginationProps";
import { giftCardCategoryRecordAction } from "../../reducer/giftCardCategoryReducer";
import { Box, TextField, Typography } from "@mui/material";

const columns: IPaginationColumn = {
  name: { label: "Name", align: "left", format: (v: any) => v },
  giftCard: { label: "Gift Card", align: "left", format: (v: any) => v },
  giftCardCountryEntity: {
    label: "Gift Card Country",
    align: "left",
    format: (v: any) => v.name ?? " ",
  },
  min: { label: "Minimum", align: "left", format: (v: any) => v },
  max: { label: "Maximum", align: "left", format: (v: any) => v },
  rate: { label: "Rate", align: "left", format: (v: any) => v },
  url: { label: "Url", align: "left", format: (v: any) => v },
  type: { label: "Type", format: (v: any) => v },
  createdAt: {
    label: "Date",
    format: (value: any) => moment(value).format("dddd, MMMM Do YYYY"),
  },
};

export default function GiftCardCategory() {
  const [search, setSearch] = useState("");
  const rows = useSelector((state: any) => state.giftCardCategory.data);
  const count = useSelector((state: any) => state.giftCardCategory.count);
  const navigate = useNavigate();

  const createAction = () => {
    navigate("/dashboard/gift-card-category/create", { replace: true });
  };

  const editAction = (id: any, data: object) => {
    localStorage.setItem("giftCardCategory", JSON.stringify(data));
    navigate(`/dashboard/gift-card-category/edit/${id}`, { replace: true });
  };

  const viewAction = (id: any, data: object) => {
    localStorage.setItem("giftCardCategory", JSON.stringify(data));
    navigate(`/dashboard/gift-card-category/view/${id}`, { replace: true });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredRows = rows.filter(
    (row: any) =>
      row.name.toLowerCase().includes(search.toLowerCase()) ||
      row.giftCard.toLowerCase().includes(search.toLowerCase()) ||
      row.giftCardCountryEntity?.name
        ?.toLowerCase()
        .includes(search.toLowerCase())
  );

  return (
    <Box>
      <TextField
        label="Search by Name, Gift Card, or Country"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        size="small"
        sx={{ mb: 2 }}
        margin="normal"
      />
      <PaginationRecord
        columns={columns}
        path="admin/admin-giftcard/type"
        recordAction={giftCardCategoryRecordAction}
        data={filteredRows}
        dataSize={count}
        name="giftCardCategory"
        createAction={createAction}
        editAction={editAction}
        viewAction={viewAction}
        deleteUrlPath={`${process.env.REACT_APP_API_URL}/admin/admin-giftcard/type`}
      />
    </Box>
  );
}
