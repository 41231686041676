import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/login";
import ProtectedComponent from "../component/protectedComponent";
import GiftCard from "../pages/giftcard/giftcard";
import GiftCardCountry from "../pages/giftcard-country/giftcardCountry";
import GiftCardCategory from "../pages/giftcard-category/giftcardCategory";
import GiftCardEditForm from "../pages/giftcard/giftCardEditForm";
import GiftCardView from "../pages/giftcard/giftCardView";
import GiftCardCreateForm from "../pages/giftcard/giftCardCreateForm";
import GiftCardCountryCreateForm from "../pages/giftcard-country/giftCardCountryCreateForm";
import GiftCardCountryEditForm from "../pages/giftcard-country/giftCardCountryEditForm";
import GiftCardCountryView from "../pages/giftcard-country/giftCardCountryView";
import GiftCardCategoryCreateForm from "../pages/giftcard-category/giftCardCategoryCreateForm";
import GiftCardCategoryEditForm from "../pages/giftcard-category/giftCardCategoryEditForm";
import GiftCardCategoryView from "../pages/giftcard-category/giftCardCategoryView";
import User from "../pages/user/user";
import Transaction from "../pages/transaction/transaction";
import GiftCardStatus from "../pages/giftcard-status/giftCardStatus";
import DashboardView from "../pages/dashboard/dashboardView";
import SettingView from "../pages/settings/settingView";
import UserView from "../pages/user/userView";
import GiftCardStatusView from "../pages/giftcard-status/giftCardStatusView";
import TransactionView from "../pages/transaction/transactionView";
import Profile from "../pages/profile/profile";
import ProfileCreateForm from "../pages/profile/profileCreateForm";
import ProfileEditForm from "../pages/profile/profileEditForm";
import Crypto from "../pages/crypto/crypto";
import CryptoCreateForm from "../pages/crypto/cryptoCreateForm";
import CryptoEditForm from "../pages/crypto/cryptoEditForm";
import CryptoView from "../pages/crypto/cryptoView";
import CryptoRequest from "../pages/cryptoRequest/cryptoRequest";
import CryptoRequestView from "../pages/cryptoRequest/cryptoRequestView"; // Import CryptoRequestView
import CurrencyRate from "../pages/currencyRate/currencyRate";
import CurrencyRateCreateForm from "../pages/currencyRate/currencyRateCreateForm";
import CurrencyRateEditForm from "../pages/currencyRate/currencyRateEditForm";
import CurrencyRateView from "../pages/currencyRate/currencyRateView";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <ProtectedComponent />,
    children: [
      {
        path: "gift-card",
        children: [
          {
            path: "list",
            element: <GiftCard />,
          },
          {
            path: "create",
            element: <GiftCardCreateForm />,
          },
          {
            path: "edit/:id",
            element: <GiftCardEditForm />,
          },
          {
            path: "view/:id",
            element: <GiftCardView />,
          },
        ],
      },
      {
        path: "profile",
        children: [
          {
            path: "list",
            element: <Profile />,
          },
          {
            path: "create",
            element: <ProfileCreateForm />,
          },
          {
            path: "edit/:id",
            element: <ProfileEditForm />,
          },
        ],
      },
      {
        path: "gift-card-country",
        children: [
          {
            path: "list",
            element: <GiftCardCountry />,
          },
          {
            path: "create",
            element: <GiftCardCountryCreateForm />,
          },
          {
            path: "edit/:id",
            element: <GiftCardCountryEditForm />,
          },
          {
            path: "view/:id",
            element: <GiftCardCountryView />,
          },
        ],
      },
      {
        path: "gift-card-category",
        children: [
          {
            path: "list",
            element: <GiftCardCategory />,
          },
          {
            path: "create",
            element: <GiftCardCategoryCreateForm />,
          },
          {
            path: "edit/:id",
            element: <GiftCardCategoryEditForm />,
          },
          {
            path: "view/:id",
            element: <GiftCardCategoryView />,
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "list",
            element: <User />,
          },
          {
            path: "view/:id",
            element: <UserView />,
          },
        ],
      },
      {
        path: "transaction",
        children: [
          {
            path: "list",
            element: <Transaction />,
          },
          {
            path: "view/:id",
            element: <TransactionView />,
          },
        ],
      },
      {
        path: "gift-card-status",
        children: [
          {
            path: "list",
            element: <GiftCardStatus />,
          },
          {
            path: "view/:id",
            element: <GiftCardStatusView />,
          },
        ],
      },
      {
        path: "dashboard",
        children: [
          {
            path: "list",
            element: <DashboardView />,
          },
        ],
      },
      {
        path: "setting",
        children: [
          {
            path: "list",
            element: <SettingView />,
          },
        ],
      },
      {
        path: "crypto",
        children: [
          {
            path: "list",
            element: <Crypto />,
          },
          {
            path: "create",
            element: <CryptoCreateForm />,
          },
          {
            path: "edit/:id",
            element: <CryptoEditForm />,
          },
          {
            path: "view/:id",
            element: <CryptoView />,
          },
        ],
      },
      {
        path: "crypto-request",
        children: [
          {
            path: "list",
            element: <CryptoRequest />,
          },
          {
            path: "view/:id",
            element: <CryptoRequestView />,
          },
        ],
      },
      {
        path: "currency-rate",
        children: [
          {
            path: "list",
            element: <CurrencyRate />,
          },
          {
            path: "create",
            element: <CurrencyRateCreateForm />,
          },
          {
            path: "edit/:id",
            element: <CurrencyRateEditForm />,
          },
          {
            path: "view/:id",
            element: <CurrencyRateView />,
          },
        ],
      },
    ],
  },
]);

export default router;
