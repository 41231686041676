// components/CurrencyRate.js

import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import { IPaginationColumn } from "../../interface/IPaginationProps";
import { currencyRateRecordAction } from "../../reducer/currencyRateReducer";
import { useNavigate } from "react-router-dom";

import axios from "axios";

const columns: IPaginationColumn = {
  name: {
    label: "Currency Name",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },
  rateToNaira: {
    label: "Rate",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },

  createdAt: {
    label: "Date",
    editable: false,
    format: (value: any) => moment(value).format("dddd, MMMM Do YYYY"),
  },
};

export default function CurrencyRate() {
  const dispatch = useDispatch();
  const rows = useSelector((state: any) => state.currencyRate.data);
  const count = useSelector((state: any) => state.currencyRate.count);
  const role = useSelector((state: any) => state.auth.role);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/admin-crypto/currency-rate`)
      .then((response) => {
        dispatch(currencyRateRecordAction(response.data));
      })
      .catch((error) => {
        console.error("Failed to fetch currency rates:", error);
      });
  }, [dispatch]);

  const editAction = (id: any, data: object) => {
    localStorage.setItem("currencyRate", JSON.stringify(data));
    navigate(`/dashboard/currency-rate/edit/${id}`, { replace: true });
  };

  const viewAction = (id: any, data: object) => {
    localStorage.setItem("currencyRate", JSON.stringify(data));
    navigate(`/dashboard/currency-rate/view/${id}`, { replace: true });
  };

  const createAction = () => {
    navigate("/dashboard/currency-rate/create", { replace: true });
  };

  return (
    <PaginationRecord
      columns={columns}
      path="admin/admin-crypto/currency-rate"
      recordAction={currencyRateRecordAction}
      data={rows}
      dataSize={count}
      editAction={role !== "staff" ? editAction : () => {}}
      deleteUrlPath={`${process.env.REACT_APP_API_URL}/admin/admin-crypto/currency-rate`}
      viewAction={viewAction}
      createAction={role !== "staff" ? createAction : () => {}}
      name="currencyRate"
    />
  );
}
