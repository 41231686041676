import * as React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import { IPaginationColumn } from "../../interface/IPaginationProps";
import { giftCardRecordAction } from "../../reducer/giftCardReducer";
import { useNavigate } from "react-router-dom";
import { Console } from "console";

const columns: IPaginationColumn = {
  //id: { label: 'ID', align: 'left', editable: false, format: (v:any) => v },
  name: {
    label: "Name",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },
  url: {
    label: "Url",
    align: "left",
    editable: true,
    type: "TextField",
    format: (v: any) => v,
  },
  type: {
    label: "Type",
    editable: true,
    type: "ComboBox",
    comboOptions: [
      { id: "sell", value: "Sell" },
      { id: "buy", value: "Buy" },
    ],
    comboField: "value",
    format: (v: any) => v,
  },
  createdAt: {
    label: "Date",
    editable: false,
    format: (value: any) => moment(value).format("dddd, MMMM Do YYYY"),
  },
};

//{ id: 'time', label: 'Time', format: (value) =>  moment(value).format("h:mm:ss a") },

export default function GiftCard() {
  const rows = useSelector((state: any) => state.giftCard.data);
  const count = useSelector((state: any) => state.giftCard.count);
  const role = useSelector((state: any) => state.auth.role);
  console.log("Role:", role);

  const navigate = useNavigate();

  const editAction = (id: any, data: object) => {
    localStorage.setItem("giftCard", JSON.stringify(data));
    navigate(`/dashboard/gift-card/edit/${id}`, { replace: true });
  };

  const viewAction = (id: any, data: object) => {
    localStorage.setItem("giftCard", JSON.stringify(data));
    navigate(`/dashboard/gift-card/view/${id}`, { replace: true });
  };

  const createAction = () => {
    navigate("/dashboard/gift-card/create", { replace: true });
  };

  return (
    <PaginationRecord
      columns={columns}
      path="admin/admin-giftcard"
      recordAction={giftCardRecordAction}
      data={rows}
      dataSize={count}
      editAction={role !== "staff" ? editAction : () => {}}
      deleteUrlPath={`${process.env.REACT_APP_API_URL}/admin/admin-giftcard`}
      viewAction={viewAction}
      createAction={role !== "staff" ? createAction : () => {}}
      name="giftCard"
    />
  );
}
