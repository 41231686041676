import * as React from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import PaginationRecord from "../../component/paginationRecord";
import {IPaginationColumn} from "../../interface/IPaginationProps";
import {giftCardRecordAction} from "../../reducer/giftCardReducer";
import {useNavigate} from "react-router-dom";
import {userRecordAction} from "../../reducer/userReducer";

const columns:IPaginationColumn  = {
    fullName: { label: 'Full Name', align: 'left', format: (v:any) => v },
    email: { label: 'Email', align: 'left', format: (v:any) => v },
    username: { label:'Username', format: (v:any) => v },
    phone: { label:'Phone', format: (v:any) => v },
    walletAmount: { label:'Wallet Amount', format: (v:any) => v },
    referralCode: { label:'Referral Code', format: (v:any) => v },
    fromReferral: { label:'From Referral', format: (v:any) => v },
    createdAt: { label: 'Date Registered', editable: false, format: (value: any) =>  moment(value).format("dddd, MMMM Do YYYY") },
};


export default function User() {

    const rows = useSelector((state: any) => state.user.data );
    const count = useSelector((state: any) => state.user.count );
    const navigate = useNavigate();


    const viewAction = (id: any, data: object) => {
        localStorage.setItem('user', JSON.stringify(data));
        navigate(`/dashboard/user/view/${id}`, {replace: false});
    };



    return (
        <PaginationRecord columns={columns} path='admin/user'
                          recordAction={userRecordAction} data={rows}
                          dataSize={count}
                          viewAction={viewAction}
                          name='user'
        />
    );
}