import {Card, CardContent, Container, Grid, MenuItem, Select, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import {useNavigate, useParams} from 'react-router-dom';
import {IGiftCard, IGiftCardCountry} from "../../interface/IGiftCard";
import theme from "../../theme";
import useFetchRequest from "../../common/fetchRequest";
import { Alert } from "@mui/material";
import fetchCollections from "../../common/fetchCollections";

const GiftCardCategoryCreateForm = () => {

    const [pending, setPending] = useState(false);
    const [giftCardCountryId, setGiftCardCountryId] = useState('0');
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [minimum, setMinimum] = useState('0');
    const [maximum, setMaximum] = useState('1');
    const [rate, setRate] = useState('1');
    const [type, setType] = useState('range');
    const [giftCardCountries, setGiftCardCountries] = useState([]);
    const [serverMessage, setServerMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [request, setRequest] = useState({});

    //snackbar
    const [open, setOpen] = useState(false);

    //fetch the record form the database
    fetchCollections(`${process.env.REACT_APP_API_URL}/admin/admin-giftcard/country?limit=200`, (result: any) => {
        setGiftCardCountries(result.data);
    });


    const callback = (data: any) => {
        setPending(false);
        setAlertColor('success');

        if(data && data.statusCode > 299) {
            setServerMessage(JSON.stringify(data.message ?? {}));
            setAlertColor('error');
        } else {
            setServerMessage('Gift card categories created successfully');
        }

        setOpen(true);

    };
    useFetchRequest( `${process.env.REACT_APP_API_URL}/admin/admin-giftcard/type`,
        request, 'POST', callback);


    const handleClose = (event: any, reason?: any) => {
        if (reason && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if(giftCardCountryId === '0') {
            setAlertColor('error');
            setServerMessage('invalid gift card country selected');
            setOpen(true);
            return;
        }

        if(type === 'range' && parseInt(minimum) > parseInt(maximum)) {
            setAlertColor('error');
            setServerMessage('minimum is greater than maximum');
            setOpen(true);
            return;
        }

        if(type === 'fixed' && parseInt(maximum) < 1) {
            setAlertColor('error');
            setServerMessage('maximum must be greater than 1');
            setOpen(true);
            return;
        }

        if(type === 'dynamic') {
            setMaximum('1');
            setMaximum('0');
        }

        setPending(true);


        const temRequest: any = {
            url: url,
            name: name,
            giftCardCountryId: parseInt(giftCardCountryId),
            min: parseInt(minimum),
            max: parseInt(maximum),
            rate: parseInt(rate),
            type: type,
        };

        if(url.trim() === ''){
            delete temRequest.url;
        }

        setRequest(temRequest);

    };

    return (
        <Container sx={{marginTop: '50px'}}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h3'>Create Gift Gard Category</Typography>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3} columns={{sm:12, md:12, xs:6}}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id='name'
                                            label='Name'
                                            value={name}
                                            fullWidth
                                            onChange={e => setName(e.target.value)}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id='minimum'
                                            label='Minimum'
                                            value={minimum}
                                            fullWidth
                                            type='number'
                                            onChange={e => setMinimum(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id='maximum'
                                            label='Maximum'
                                            value={maximum}
                                            fullWidth
                                            type='number'
                                            onChange={e => setMaximum(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id='rate'
                                            label='Rate'
                                            value={rate}
                                            fullWidth
                                            type='number'
                                            onChange={e => setRate(e.target.value)}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id='url'
                                            label='Url'
                                            value={url}
                                            type='url'
                                            fullWidth
                                            onChange={e => setUrl(e.target.value)}
                                        />
                                    </Grid>


                                    <Grid item xs={6}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="country"
                                            value={giftCardCountryId}
                                            label="Country"
                                            fullWidth
                                            required
                                            onChange={e => setGiftCardCountryId(e.target.value)}
                                        >
                                            <MenuItem value='0' disabled>Select Gift Card Country</MenuItem>
                                            {
                                                giftCardCountries.map((item: IGiftCardCountry) => (<MenuItem key={`${item.id}`} value={`${item.id}`}>{item.name} - {item.giftCardEntity.name}</MenuItem>))
                                            }
                                        </Select>

                                    </Grid>

                                    <Grid item xs={6}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="type"
                                            value={type}
                                            label="Type"
                                            fullWidth
                                            required
                                            onChange={e => setType(e.target.value)}
                                        >
                                            <MenuItem value='range'>Range</MenuItem>
                                            <MenuItem value='fixed'>Fixed</MenuItem>
                                            <MenuItem value='dynamic'>Dynamic</MenuItem>
                                        </Select>

                                    </Grid>


                                    {!pending && <Grid item xs={12}>
                                        <Box paddingX={{
                                            xs:theme.spacing(2),
                                            md:theme.spacing(20),
                                            sm:theme.spacing(20)

                                        }} paddingY={4}>
                                            <Button sx={{width:'100%'}} size={"large"} type='submit' variant='contained'> Submit </Button>
                                        </Box>

                                    </Grid>}

                                    {pending && <Grid item xs={12}>
                                        <Box paddingX={{
                                            xs:theme.spacing(2),
                                            md:theme.spacing(20),
                                            sm:theme.spacing(20)

                                        }} paddingY={4}>
                                            <LoadingButton
                                                loading
                                                loadingPosition="start"
                                                startIcon={<SaveIcon />}
                                                variant="contained"
                                                fullWidth
                                            >
                                                Loading
                                            </LoadingButton>
                                        </Box>

                                    </Grid>}

                                </Grid>
                            </form>

                            <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal:'center'}}  autoHideDuration={6000} onClose={handleClose}>
                                <Alert severity={alertColor as any} onClose={handleClose} sx={{ width: '100%' }}>
                                    { serverMessage }
                                </Alert>
                            </Snackbar>

                        </Grid>


                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};

export default GiftCardCategoryCreateForm;
