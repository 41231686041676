import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../reducer/authReducer";

const useCollection = (url: any, action: (value: any) => void) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  console.log(token);
  useEffect(() => {
    const abortController = new AbortController();

    fetch(url, {
      signal: abortController.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: !token ? "" : "Bearer " + token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          //logout the user possibly forbidden
          dispatch(logoutAction());
          navigate("/");
          throw new Error("authentication error");
        }

        return res.json();
      })

      .then((data) => {
        //console.log(data);
        action({
          data: data.data.items ?? data,
          count: data.data.meta ? data.data.meta.totalItems : 0,
        });
      })
      .catch((e) => {
        //console.log(e);
      });

    return () => {
      abortController.abort();
    };
  }, [url]);
};

export default useCollection;
