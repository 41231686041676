import { Alert, Box, Button, Card, CardContent, Container, Divider, Grid, ImageList, ImageListItem, List, ListItem, ListItemText, Snackbar, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from 'react-router-dom';
import { IGiftCard } from "../../interface/IGiftCard";
import theme from "../../theme";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import useFetchRequest from "../../common/fetchRequest";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';

const TransactionView = () => {

    //snackbar
    const [open, setOpen] = useState(false);

    const { id } = useParams();
    const [pending, setPending] = useState(false);
    const [status, setStatus] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [serverMessage, setServerMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [request, setRequest] = useState({});
    const navigate = useNavigate();

    const userRole = localStorage.getItem('role');
    const canClickRoles: any[] = ['super', 'admin'];
    const canClick = canClickRoles.includes(userRole)


    const callback = (data: any) => {
        setPending(false);
        setAlertColor('success');

        if (data && data.statusCode > 299) {
            setServerMessage(JSON.stringify(data.message ?? {}));
            setAlertColor('error');
        } else {
            setServerMessage('Transaction status updated successfully');
            setShowForm(false);
        }

        setOpen(true);

    };

    const rowString = localStorage.getItem("transaction") as any;
    const row: any = JSON.parse(rowString);

    useFetchRequest(`${process.env.REACT_APP_API_URL}/admin/transaction/update-transfer/${row.id}`,
        request, 'POST', callback);


    if (!row) {
        navigate('/dashboard/transaction/list', { replace: true })
        return (<></>);
    }

    let bankName = '';
    let nameOnAccount = '';
    let accountNumber = '';

    if (row.requestData && row.type === 'trans2bank') {
        const bankData: any = JSON.parse(row.requestData);
        bankName = bankData.bankName;
        nameOnAccount = bankData.accountName;
        accountNumber = bankData.accountNumber;
    }


    const handleClose = (event: any, reason?: any) => {
        if (reason && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (event: any, isStatus: boolean) => {
        event?.preventDefault();
        setPending(true);


        setRequest({
            isApprove: isStatus
        });

    };

    return (
        <Container sx={{ marginTop: '50px' }}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h3'>Transaction Detail</Typography>
                        </Grid>

                        <Grid item xs={5} marginTop={5}>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Full Name</Typography>} secondary={row.user.fullName} />
                                </ListItem>

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>User Email</Typography>} secondary={row.user.email} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Channel</Typography>} secondary={row.channel} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Amount</Typography>}
                                        secondary={row.amount} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Transaction Type</Typography>}
                                        secondary={row.type} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Transaction Status</Typography>}
                                        secondary={row.status} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Transaction Reference</Typography>}
                                        secondary={row.reference} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Fee</Typography>}
                                        secondary={row.fee} />
                                </ListItem>

                                <Divider />

                                <ListItem>
                                    <ListItemText primary={<Typography fontWeight='bold'>Product Name</Typography>}
                                        secondary={row.productName} />
                                </ListItem>

                                <Divider />

                            </List>
                        </Grid>

                        <Grid container item xs={7} marginTop={3}>
                            {
                                row.type === 'trans2bank' &&
                                <Grid item xs={12}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemText primary={<Typography fontSize={15} fontWeight='bold'>BANK DETAILS</Typography>} />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText primary={<Typography fontWeight='bold'>Bank Name</Typography>} secondary={bankName} />
                                        </ListItem>

                                        <Divider />

                                        <ListItem>
                                            <ListItemText primary={<Typography fontWeight='bold'>Account Number</Typography>} secondary={accountNumber} />
                                        </ListItem>

                                        <Divider />

                                        <ListItem>
                                            <ListItemText primary={<Typography fontWeight='bold'>Name On Account</Typography>}
                                                secondary={nameOnAccount} />
                                        </ListItem>

                                        <Divider />

                                    </List>
                                </Grid>
                            }

                            {
                                row.type !== 'trans2bank' &&
                                <Grid item xs={12}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemText primary={<Typography fontSize={15} fontWeight='bold'>Request Data</Typography>} />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText primary={<Typography fontWeight='bold'>Data</Typography>} secondary={row.requestData} />
                                        </ListItem>

                                        <Divider />

                                    </List>
                                </Grid>
                            }
                        </Grid>
                        {
                            showForm && row.status === 'awaiting approval' && <form /*onSubmit={handleSubmit} */ >

                                {!pending && canClick && <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <Box paddingX={{
                                            xs: theme.spacing(2),
                                            md: theme.spacing(20),
                                            sm: theme.spacing(20)

                                        }} paddingY={4}>
                                            <Button sx={{ width: '100%' }} onClick={() => handleSubmit(this, true)} size={"large"} variant='contained'> APPROVE </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box paddingX={{
                                            xs: theme.spacing(2),
                                            md: theme.spacing(20),
                                            sm: theme.spacing(20)

                                        }} paddingY={4}>
                                            <Button sx={{ width: '100%' }} onClick={() => handleSubmit(this, false)} color="error" size={"large"} variant='contained'> REJECT </Button>
                                        </Box>
                                    </Grid>

                                </Grid>}

                                {pending && canClick && <Grid item xs={12}>
                                    <Box paddingX={{
                                        xs: theme.spacing(2),
                                        md: theme.spacing(20),
                                        sm: theme.spacing(20)

                                    }} paddingY={4}>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="contained"
                                            fullWidth
                                        >
                                            Loading
                                        </LoadingButton>
                                    </Box>

                                </Grid>}
                            </form>
                        }

                        <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
                            <Alert severity={alertColor as any} onClose={handleClose} sx={{ width: '100%' }}>
                                {serverMessage}
                            </Alert>
                        </Snackbar>

                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};

export default TransactionView;
