import {
  Card,
  CardContent,
  Container,
  Grid,
  Snackbar,
  Typography,
  TextField,
  Box,
  Button,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useParams } from "react-router-dom";
import theme from "../../theme";
import useFetchRequest from "../../common/fetchRequest";

const CryptoEditForm = () => {
  const { id } = useParams();
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();

  // State for all crypto fields
  const [cryptoName, setCryptoName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState(0);
  const [QRCode_imageUrl, setQRCode_imageUrl] = useState("");
  const [serverMessage, setServerMessage] = useState("");
  const [alertColor, setAlertColor] = useState("success");
  const [request, setRequest] = useState({});

  // Snackbar
  const [open, setOpen] = useState(false);

  // Callback function after API call
  const callback = (data: any) => {
    setPending(false);
    setAlertColor("success");

    if (data && data.statusCode > 299) {
      setServerMessage(JSON.stringify(data.message ?? {}));
      setAlertColor("error");
      setOpen(true);
    } else {
      setServerMessage("Crypto edited successfully");
      setAlertColor("success");
      setOpen(true);
      // Delay the navigation to allow the Snackbar to display
      setTimeout(() => {
        navigate("/dashboard/crypto/list");
      }, 2000); // 2-second delay
    }
  };

  // Custom hook for making fetch requests
  useFetchRequest(
    `${process.env.REACT_APP_API_URL}/admin/admin-crypto`,
    request,
    "PATCH",
    callback
  );

  // Retrieve crypto data from localStorage
  const rowString = localStorage.getItem("crypto") as any;
  const row = rowString ? JSON.parse(rowString) : null;

  // Set form fields with crypto data
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!row) return;

      setCryptoName(row.cryptoName ?? "");
      setWalletAddress(row.walletAddress ?? "");
      setLogoUrl(row.logoUrl ?? "");
      setDescription(row.description ?? "");
      setRate(row.rate ?? 0);
      setQRCode_imageUrl(row.QRCode_imageUrl ?? "");
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  if (!row) {
    navigate("/dashboard/crypto/list", { replace: true });
    return <></>;
  }

  const handleClose = (event: any, reason?: any) => {
    if (reason && reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPending(true);

    setRequest({
      cryptoName: cryptoName,
      walletAddress: walletAddress,
      logoUrl: logoUrl,
      description: description,
      rate: rate,
      QRCode_imageUrl: QRCode_imageUrl,
      id: parseInt(id as string),
    });
  };

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="center"
                fontWeight="bold"
                variant="h5"
                component="h3"
              >
                Edit Crypto
              </Typography>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} columns={{ sm: 12, md: 12, xs: 6 }}>
                  <Grid item xs={6}>
                    <TextField
                      id="cryptoName"
                      label="Crypto Name"
                      value={cryptoName}
                      fullWidth
                      onChange={(e) => setCryptoName(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="walletAddress"
                      label="Wallet Address"
                      value={walletAddress}
                      fullWidth
                      onChange={(e) => setWalletAddress(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="logoUrl"
                      label="Logo URL"
                      value={logoUrl}
                      type="url"
                      fullWidth
                      onChange={(e) => setLogoUrl(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="description"
                      label="Description"
                      value={description}
                      fullWidth
                      multiline
                      rows={4}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="rate"
                      label="Rate"
                      value={rate}
                      type="number"
                      fullWidth
                      onChange={(e) => setRate(parseFloat(e.target.value))}
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="QRCodeImageUrl"
                      label="QR Code Image URL"
                      value={QRCode_imageUrl}
                      type="url"
                      fullWidth
                      onChange={(e) => setQRCode_imageUrl(e.target.value)}
                      required
                    />
                  </Grid>

                  {!pending && (
                    <Grid item xs={12}>
                      <Box
                        paddingX={{
                          xs: theme.spacing(2),
                          md: theme.spacing(20),
                          sm: theme.spacing(20),
                        }}
                        paddingY={4}
                      >
                        <Button
                          sx={{ width: "100%" }}
                          size={"large"}
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  )}

                  {pending && (
                    <Grid item xs={12}>
                      <Box
                        paddingX={{
                          xs: theme.spacing(2),
                          md: theme.spacing(20),
                          sm: theme.spacing(20),
                        }}
                        paddingY={4}
                      >
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          fullWidth
                        >
                          Loading
                        </LoadingButton>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </form>

              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  severity={alertColor as any}
                  onClose={handleClose}
                  sx={{ width: "100%" }}
                >
                  {serverMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CryptoEditForm;
