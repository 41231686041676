import {Card, CardContent, Container, Grid, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from 'react-router-dom';
import {IGiftCard, IGiftCardCountry} from "../../interface/IGiftCard";
import theme from "../../theme";
import Paper from "@mui/material/Paper";

const GiftCardCountryView = () => {

    const navigate = useNavigate();

    const rowString = localStorage.getItem("giftCardCountry") as any;
    const row: IGiftCardCountry | null = rowString ? JSON.parse(rowString) as IGiftCardCountry : null;

    if(!row) {
        navigate('/dashboard/gift-card-country/list', { replace: true })
        return (<></>);
    }

    return (
        <Container sx={{marginTop: '50px'}}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h3'>Gift Gard Country</Typography>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Name </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.name} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Gift Card Type </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.giftCardEntity.name} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Url Image </Typography>

                                <img alt='url image' src={row.url} />
                            </Paper>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};

export default GiftCardCountryView;
