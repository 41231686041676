import {Card, CardContent, Container, Grid, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from 'react-router-dom';
import {IGiftCard, IGiftCardCategory, IGiftCardCountry} from "../../interface/IGiftCard";
import theme from "../../theme";
import Paper from "@mui/material/Paper";

const GiftCardCategoryView = () => {

    const navigate = useNavigate();

    const rowString = localStorage.getItem("giftCardCategory") as any;
    const row: IGiftCardCategory | null = rowString ? JSON.parse(rowString) as IGiftCardCategory : null;

    if(!row) {
        navigate('/dashboard/gift-card-category/list', { replace: true })
        return (<></>);
    }

    return (
        <Container sx={{marginTop: '50px'}}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' fontWeight='bold' variant='h5' component='h3'>Gift Card Category</Typography>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Name </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.name} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Gift Card </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.giftCard} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Gift Card Country </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.giftCardCountryEntity.name} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Minimum </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.min} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Maximum </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.max} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Rate </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.rate} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Type </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.type} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Gift Card Country </Typography>

                                <Typography fontSize={theme.spacing(2)}> {row.giftCardCountryEntity.name} </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} marginTop={4}>
                            <Paper elevation={3} sx={{padding: 4}}>
                                <Typography fontWeight='bold' fontSize={theme.spacing(2)}> Url Image </Typography>

                                <img alt='url image' src={row.url} />
                            </Paper>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
};

export default GiftCardCategoryView;
